// Adds a consent dialog when relevant, handles saving the selected options
// and inserts scripts and other elements that consent has been acquired for.
// Can also insert buttons to trigger the dialog.
//
// If using groups, the required one(s) are only for show and not included in
// the saved value.
//
// Keeping IE11 compatible and free of dependencies for portability.

/*
eslint-disable
func-names,
no-var,
prefer-arrow-callback,
prefer-destructuring,
prefer-template,
*/

(function (win) {
  // E.g. 'consent_example_com'
  var COOKIE_NAME = 'consent_' + win.location.hostname.replace(/[^\w]/, '_');
  var COOKIE_ACCEPT_VALUE = '1';
  var COOKIE_REJECT_VALUE = '0';
  var BASE_CLASS = 'consent';
  var KEY_VAL_SEP = ':';
  var GROUP_SEP = '|';

  var doc = win.document;
  var body = doc.body;
  var dialog;
  var dialogFocusable;
  var pressedOpener;
  var opt;
  var consentItems;
  var hasGroups = false;

  // Some filter/map shorthands

  function isRequired(group) {
    return group.isRequired;
  }

  function isOptional(group) {
    return !group.isRequired;
  }

  function getId(group) {
    return group.id;
  }

  /**
   * Check if two arrays contain the same values, in any order.
   *
   * @param {Array} a
   * @param {Array} b
   * @returns {boolean}
   */
  function arraysHaveSameValues(a, b) {
    if (a.length !== b.length) {
      return false;
    }

    // Clone with slice to avoid mutating the original.
    const sortedA = a.slice().sort();
    const sortedB = b.slice().sort();

    return sortedA.every(function (val, i) {
      return sortedB[i] === val;
    });
  }

  /**
   * Get the consent cookie value.
   *
   * @returns {string}
   */
  function getCookie() {
    // Split on a value like `; one=1; two=2`. No match means a one item array
    // with the entire cookie string.
    var parts = ('; ' + doc.cookie).split('; ' + COOKIE_NAME + '=');
    return parts.length > 1 ? parts[1].split(';')[0] : null;
  }

  /**
   * Set the consent cookie value.
   *
   * @param {string} value
   */
  function setCookie(value) {
    doc.cookie =
      COOKIE_NAME + '=' + value + '; max-age=31536000; path=/; samesite=strict';
  }

  /**
   * Get saved consent status, if any
   *
   * @returns {boolean|object|undefined} - True/false if not using groups and
   *   an accept/reject has been saved. An object of groupName: true/false for
   *   each group if using groups, and undefined if no decision has been made.
   */
  function getConsentStatus() {
    var val = getCookie();
    if (!val) {
      return undefined;
    }
    if (val.indexOf(KEY_VAL_SEP) !== -1) {
      return val.split(GROUP_SEP).reduce(function (groups, rawGroup) {
        var groupData = rawGroup.split(KEY_VAL_SEP);
        groups[groupData[0]] = groupData[1] === COOKIE_ACCEPT_VALUE;
        return groups;
      }, {});
    }
    return val === COOKIE_ACCEPT_VALUE;
  }

  /**
   * @param {string} groupId
   * @returns {boolean}
   */
  function isRequiredGroup(groupId) {
    if (!hasGroups) {
      return false;
    }
    return opt.groups.filter(isRequired).map(getId).indexOf(groupId) !== -1;
  }

  /**
   * Get all elements matching a selector.
   *
   * @param {string} selector
   * @param {HTMLElement} [rootNode]
   * @returns {Array.<HTMLElement>}
   */
  function getEls(selector, rootNode) {
    return Array.prototype.slice.call(
      (rootNode || doc).querySelectorAll(selector),
      0,
    );
  }

  /**
   * Create an element.
   *
   * @param {string} tagName
   * @param {object} [attrs]
   * @param {string|HTMLElement|Array.<string|HTMLElement>} [children]
   * @returns {HTMLElement}
   */
  function el(tagName, attrs, children) {
    var elem = doc.createElement(tagName);
    var key;
    if (attrs) {
      // eslint-disable-next-line no-restricted-syntax
      for (key in attrs) {
        if (Object.prototype.hasOwnProperty.call(attrs, key)) {
          elem.setAttribute(key, attrs[key]);
        }
      }
    }
    if (children) {
      []
        .concat(children)
        .filter(Boolean)
        .map(function (c) {
          return typeof c === 'string' ? doc.createTextNode(c) : c;
        })
        .forEach(function (c) {
          elem.appendChild(c);
        });
    }
    return elem;
  }

  /**
   * Add all scripts, and other things that require consent, to the DOM.
   */
  function insertConsentItems() {
    /* eslint-disable vars-on-top, no-continue */

    var status = getConsentStatus();
    if (!status) {
      return;
    }

    // A somewhat unusual loop is required since the array is modified during
    // iteration by removing data for inserted items. This way this function
    // can be called any number of times without risking duplicate scripts.
    var i = 0;
    while (consentItems.length > i) {
      var itemData = consentItems[i];

      // Skip rejected group and jump forward one index for the next iteration.
      if (
        hasGroups &&
        itemData.group &&
        typeof status === 'object' &&
        !status[itemData.group]
      ) {
        i += 1;
        continue;
      }

      var node = el(itemData.tag, itemData.attrs, itemData.text);
      var appendTarget =
        ['meta', 'link', 'script'].indexOf(itemData.tag) !== -1
          ? doc.head
          : body;
      appendTarget.appendChild(node);

      consentItems.splice(i, 1);
    }
  }

  function removeDialog() {
    /* eslint-disable no-use-before-define */

    dialog.removeEventListener('keydown', handleDialogClick);
    dialog.removeEventListener('keydown', handleDialogKeydown);

    dialog.parentNode.removeChild(dialog);
    dialog = null;

    if (pressedOpener) {
      pressedOpener.focus();
      pressedOpener = null;
    }
  }

  /**
   * Primary action button handler, always an 'accept all'.
   */
  function handlePrimaryClick() {
    if (hasGroups) {
      setCookie(
        opt.groups
          .filter(isOptional)
          .map(function (g) {
            return g.id + KEY_VAL_SEP + COOKIE_ACCEPT_VALUE;
          })
          .join(GROUP_SEP),
      );
    } else {
      setCookie(COOKIE_ACCEPT_VALUE);
    }
    insertConsentItems();
    removeDialog();
  }

  /**
   * Primary action button handler, either a 'reject all' or a 'save settings'
   * depending on groups.
   */
  function handleSecondaryClick() {
    if (hasGroups) {
      setCookie(
        getEls('input[type="checkbox"]', dialog)
          .filter(function (input) {
            return !isRequiredGroup(input.name);
          })
          .map(function (input) {
            return (
              input.name +
              KEY_VAL_SEP +
              (input.checked ? COOKIE_ACCEPT_VALUE : COOKIE_REJECT_VALUE)
            );
          })
          .join(GROUP_SEP),
      );
    } else {
      setCookie(COOKIE_REJECT_VALUE);
    }
    insertConsentItems();
    removeDialog();
  }

  /**
   * Close the dialog when clicking the backdrop.
   *
   * @param {Event} e
   */
  function handleDialogClick(e) {
    if (e.target.classList.contains(BASE_CLASS + '-backdrop')) {
      // Skip focus restoration since mouse users can scroll - the pressed
      // opener could have been scrolled out of view and a focus will scroll
      // back to that position which could be jarring.
      pressedOpener = null;
      removeDialog();
    }
  }

  /**
   * Close the dialog on escape and trap keyboard focus.
   *
   * @param {KeyboardEvent} e
   */
  function handleDialogKeydown(e) {
    if (e.key === 'Esc' || e.key === 'Escape') {
      removeDialog();
    } else if (e.key === 'Tab' && dialogFocusable) {
      var focused = doc.activeElement;
      var firstFocusable = dialogFocusable[0];
      var lastFocusable = dialogFocusable[dialogFocusable.length - 1];
      if (focused === firstFocusable && e.shiftKey) {
        lastFocusable.focus();
        e.preventDefault();
      } else if (
        (focused === lastFocusable && !e.shiftKey) ||
        !dialog.contains(focused)
      ) {
        firstFocusable.focus();
        e.preventDefault();
      }
    }
  }

  /**
   * Join class names, excluding falsy values.
   *
   * @param {Array.<string>} names
   * @returns {string}
   */
  function cls(names) {
    return names.filter(Boolean).join(' ');
  }

  /**
   * Add the consent dialog, only making it a 'real' modal if it's triggered
   * manually via an opener button.
   *
   * @param {boolean} isTriggeredManually
   */
  function addDialog(isTriggeredManually) {
    var btnBaseClass = BASE_CLASS + '__action';
    var secondaryBtn = el(
      'button',
      {
        type: 'button',
        class: cls([
          btnBaseClass,
          opt.actionsAlign === 'full' && btnBaseClass + '--full',
          btnBaseClass + '--secondary',
          btnBaseClass + '--secondary--' + opt.theme,
          opt.secondaryActionClass,
        ]),
      },
      opt.secondaryActionText,
    );
    var primaryBtn = el(
      'button',
      {
        type: 'button',
        class: cls([
          btnBaseClass,
          opt.actionsAlign === 'full' && btnBaseClass + '--full',
          btnBaseClass + '--primary',
          btnBaseClass + '--primary--' + opt.theme,
          opt.primaryActionClass,
        ]),
      },
      opt.primaryActionText,
    );
    var policyPageLink =
      opt.policyPageUrl && opt.policyPageTitle
        ? el('a', { href: opt.policyPageUrl }, opt.policyPageTitle)
        : null;

    var posClasses = opt.position.split('-').map(function (c) {
      return BASE_CLASS + '--pos-' + c;
    });

    var groups;
    if (hasGroups) {
      var status = getConsentStatus();
      groups = el(
        'fieldset',
        { class: BASE_CLASS + '__groups' },
        [el('legend', null, opt.groupsLegend)].concat(
          opt.groups.map(function (group) {
            var inputAttr = { type: 'checkbox', name: group.id };
            if (group.isRequired) {
              inputAttr.checked = '';
              inputAttr.disabled = '';
              inputAttr.required = '';
            } else if (typeof status === 'object' && status[group.id]) {
              inputAttr.checked = '';
            }
            return el('label', { class: BASE_CLASS + '__group' }, [
              el('input', inputAttr),
              el('span', null, group.label),
            ]);
          }),
        ),
      );
    }

    var titleId = 'consent-title';
    var dialogAttr = {
      'role': 'dialog',
      'aria-labelledby': titleId,
      'class': cls(
        [BASE_CLASS, BASE_CLASS + '--theme-' + opt.theme].concat(posClasses),
      ),
    };
    if (isTriggeredManually) {
      dialogAttr.class += ' ' + BASE_CLASS + '--modal';
      dialogAttr['aria-modal'] = 'true';
    }
    dialog = el(
      'div',
      {
        class: cls([
          BASE_CLASS + '-backdrop',
          isTriggeredManually && BASE_CLASS + '-backdrop--modal',
        ]),
      },
      el('div', dialogAttr, [
        el('div', { class: BASE_CLASS + '__content' }, [
          el('h2', { class: BASE_CLASS + '__title', id: titleId }, opt.title),
          el('p', { class: BASE_CLASS + '__text' }, [
            opt.text + ' ',
            policyPageLink && opt.policyPagePrefix
              ? opt.policyPagePrefix + ' '
              : '',
            policyPageLink,
          ]),
        ]),
        groups,
        el(
          'div',
          {
            class: cls([
              BASE_CLASS + '__actions',
              BASE_CLASS + '__actions--' + opt.actionsAlign,
            ]),
          },
          [secondaryBtn, primaryBtn],
        ),
      ]),
    );

    body.insertBefore(dialog, body.firstElementChild);

    secondaryBtn.addEventListener('click', handleSecondaryClick, false);
    primaryBtn.addEventListener('click', handlePrimaryClick, false);

    if (isTriggeredManually) {
      dialogFocusable = getEls(
        'a, button:not(:disabled), input:not(:disabled)',
        dialog,
      );
      dialog.addEventListener('click', handleDialogClick);
      dialog.addEventListener('keydown', handleDialogKeydown);
      requestAnimationFrame(function () {
        secondaryBtn.focus();
      });
    }
  }

  /**
   * @param {Event} e
   */
  function handleOpenerClick(e) {
    if (!dialog) {
      pressedOpener = e.currentTarget;
      addDialog(true);
    }
  }

  function addDialogOpeners() {
    var baseOpener = el(
      'button',
      {
        type: 'button',
        class: cls([BASE_CLASS + '-opener', opt.openerClass]),
      },
      opt.openerText,
    );

    var targets = opt.openerAppendSelector
      ? getEls(opt.openerAppendSelector)
      : [];
    targets.forEach(function (target) {
      var opener = baseOpener.cloneNode(true);
      opener.addEventListener('click', handleOpenerClick, false);
      target.appendChild(opener);
    });
  }

  function ready() {
    opt = win.consentOptions;
    if (!opt) {
      throw new Error('Missing consent options');
    }

    consentItems = win.consentItems || [];
    hasGroups = Boolean(opt.groups && opt.groups.length);

    var status = getConsentStatus();
    var statusType = typeof status;
    // Ask for consent if it's missing or if the saved value doesn't match
    // what's currently asked. Could be a change from no groups to groups and
    // vice versa, or that the available groups have changed.
    if (
      status === undefined ||
      (hasGroups && statusType === 'boolean') ||
      (!hasGroups && statusType === 'object') ||
      (hasGroups &&
        statusType === 'object' &&
        !arraysHaveSameValues(
          Object.keys(status),
          opt.groups.filter(isOptional).map(getId),
        ))
    ) {
      addDialog(false);
    } else if (status === true || statusType === 'object') {
      insertConsentItems();
    }

    addDialogOpeners();
  }

  if (doc.readyState === 'interactive' || doc.readyState === 'complete') {
    ready();
  } else {
    doc.addEventListener('DOMContentLoaded', ready, false);
  }
})(window);
